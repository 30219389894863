@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  border: 0;
  outline: 0;
  font-family: "MabinogiClassicR";
}

@font-face {
  font-family: "MabinogiClassicR";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2207-01@1.0/MabinogiClassicR.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}
